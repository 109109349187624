<template>
  <div>
    <v-dialog
      scrollable
      v-model="show_dialog"
      width="900"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h5 white--text primary_2 d-flex justify-space-between align-center"
        >
          <div>{{ title }} Offers</div>

          <div>
            <v-btn @click="show_dialog = false" icon
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="py-5 text-center text-h6">
          <v-data-table
            :headers="headers"
            :items="offer_list"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
            loading-text="Loading...."
          >
            <template v-slot:[`item.bank_logo`]="{ item }">
              <img :src="item.bank_logo" :alt="item.title" class="bank-logo" />
            </template>
            <template v-slot:[`item.offer_status`]="{ item }">
              <div v-if="item.offer_status">
                {{ item.offer_status }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.loan_status`]="{ item }">
              <div v-if="item.loan_status">
                {{ item.loan_status }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.benefits`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-eye-outline
                  </v-icon>
                </template>
                <ul>
                  <!-- {{item.benefits}} -->
                  <li
                    v-for="(benefit, index) in item.credit_card_benefits"
                    :key="index"
                  >
                    {{ benefit }}
                  </li>
                </ul>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      title: "",
      offer_list: [],
      headers: [],
      pl_offer_header: [
        {
          text: "Bank Logo",
          value: "bank_logo",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Title",
          value: "title",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Interest Rate",
          value: "interest_rate",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Loan Upto",
          value: "loan_upto",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Tenure",
          value: "tenure",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Loan Status",
          value: "loan_status",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Offer Status",
          value: "offer_status",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
      ],
      cc_offer_header: [
        {
          text: "Bank Logo",
          value: "bank_logo",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Title",
          value: "title",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Joining Fees",
          value: "joining_fees",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Annual Charges",
          value: "annual_charges",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Loan Status",
          value: "banksathi_loan_status",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Offer Status",
          value: "offer_status",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Benefits",
          value: "benefits",
          sortable: false,
          class: "blue-grey lighten-4 font-weight-bold",
        },
      ],
    };
  },
  methods: {
    async openOffersListDialog(title, list) {
      this.show_dialog = true;
      this.title = title;
      if (title == "Credit Card") {
        this.headers = this.cc_offer_header;
      } else if (title == "Personal Loan") {
        this.headers = this.pl_offer_header;
      }
      this.offer_list = list;
    },
  },
};
</script>
<style scoped>
.bank-logo {
  width: 100px;
  height: 80px;
}
</style>
